<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 profile-screen delegates-screen full-width gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_profile-help-online-delegations'
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("profile.profile.delegated-by") }}
        </div>
        <template v-slot:right>
          <button @click="search = !search">
            <icon icon="#cx-hea1-search" />
          </button>
        </template>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="searchQuery = ''"
        :simpleSearch="true"
      ></search>
    </template>
    <div
      v-if="listOfDelegators && listOfDelegators.length"
      class="delegates-body"
    >
      <!-- Header -->
      <ul class="clebex-item-section pill delegates-header">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper grey-bg">
            <!-- Name -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.name")
              }}</span>
            </span>
            <!-- Email -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.profile.email")
              }}</span>
            </span>
            <!-- Permission -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.permission")
              }}</span>
            </span>
            <!-- Start date -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.from")
              }}</span>
            </span>
            <!-- End date -->
            <span class="label">
              <span class="highlight">{{
                displayLabelName("profile.delegate.to")
              }}</span>
            </span>
          </div>
        </li>
      </ul>
      <!-- Items -->
      <ul class="clebex-item-section pill mt-0 mb-0">
        <li
          v-for="item in listOfDelegators"
          :key="item.id"
          class="clebex-item-section-item"
        >
          <div class="clebex-item-content-wrapper">
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.name") }}:
              </span>
              <span class="highlight"
                >{{ item.delegator.first_name }}
                {{ item.delegator.last_name }}</span
              >
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.profile.email") }}:
              </span>
              <span class="highlight">{{ item.delegator.email }}</span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.permission") }}:
              </span>
              <span class="highlight">{{ item.permission.name }} </span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.from") }}:
              </span>
              <span class="highlight"
                >{{ displayDate(item.datetime_from) }}
              </span>
            </div>
            <div class="delegates-item-column">
              <span class="column-name"
                >{{ displayLabelName("profile.delegate.to") }}:
              </span>
              <div class="highlight">
                {{ displayDate(item.datetime_to) }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <template v-slot:footer></template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ProfileDelegatedBy",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: false,
      searchQuery: "",
      helpSlug: "profile-delegations"
    };
  },
  created() {
    this.setPaginationData(null);
    this.setInitialValues();
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat"]),
    ...mapState("delegates", ["delegatedBy"]),
    listOfDelegators() {
      if (this.delegatedBy && this.delegatedBy.length) {
        const query = this.searchQuery;
        if (query && query.length > 1) {
          return this.delegatedBy.filter(item =>
            `${item.delegator.first_name} ${item.delegator.last_name} ${item.delegator.email} ${item.permission.name}`
              .toLowerCase()
              .includes(query.toLowerCase())
          );
        } else {
          return this.delegatedBy;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("delegates", ["getDelegations"]),
    ...mapActions("pagination", ["setPaginationData"]),
    modifySearchQuery(searchQuery) {
      this.searchQuery = searchQuery;
    },
    setInitialValues() {
      this.getDelegations(true);
      if (!this.isMobileDevice()) {
        this.$store.commit("global/setIsFullWidthScreen", true, {
          root: true
        });
      }
      this.modifySearchQuery("");
    },
    displayDate(date) {
      if (!date) {
        return "";
      }
      return formatDate(date, this.globalDateFormat);
    }
  },
  beforeUnmount() {
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", false, {
        root: true
      });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
